/* -----------------------------------------------------------------------------
 *
 * Atoms: Navigation
 *
 * ----------------------------------------------------------------------------- */

.navigation::after {
  content: '';
  display: table;
  clear: both;
}

/* -----------------------------------------------------------------------------
  * Sticky Navigation
  * ----------------------------------------------------------------------------- */

/* [ data-sticky-nav ].sticky {
  position: fixed;
  width: 100%;
} */
