/* -----------------------------------------------------------------------------
 *
 * Molecules: Modal
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/common.css';

.modal {
  display: none;
  position: relative;
  z-index: 999;
}

.modal.isOpen {
  display: block;
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: color-mod( var( --color-mercury ) a( 0.6 ) );
}

.modalContainer {
  box-sizing: border-box;
  min-width: 40%;
  max-height: 100vh;
  padding: 48px 32px 32px;
  overflow-y: auto;
  border-radius: 4px;
  background-color: var( --color-mine-shaft-1 );
  color: color-mod( var( --color-black ) );
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 12px;
  border: 0;
  border-bottom-left-radius: 6px;
  background: transparent;
  background-color: var( --color-black );
  color: var( --color-cyan-1 );
  font-size: 0;
  cursor: pointer;

  span {
    pointer-events: none;
  }
}

.modalClose::before {
  @mixin icomoon;

  content: var( --icon-close );
  font-size: rem( 24px );
}

.modalContent {
  margin-top: 24px;
  margin-bottom: 24px;
}

/* -----------------------------------------------------------------------------
 * Animation Styles
 * ----------------------------------------------------------------------------- */

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY( 15% );
  }

  to {
    transform: translateY( 0 );
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY( 0 );
  }

  to {
    transform: translateY( -10% );
  }
}

.micromodalSlide[ aria-hidden='false' ] .modalOverlay {
  animation: mmfadeIn 0.3s cubic-bezier( 0, 0, 0.2, 1 );
}

.micromodalSlide[ aria-hidden='false' ] .modalContainer {
  animation: mmslideIn 0.3s cubic-bezier( 0, 0, 0.2, 1 );
}

.micromodalSlide[ aria-hidden='true' ] .modalOverlay {
  animation: mmfadeOut 0.3s cubic-bezier( 0, 0, 0.2, 1 );
}

.micromodalSlide[ aria-hidden='true' ] .modalContainer {
  animation: mmslideOut 0.3s cubic-bezier( 0, 0, 0.2, 1 );
}

.micromodalSlide .modalContainer,
.micromodalSlide .modalOverlay {
  will-change: transform;
}

/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */

.title {
  display: block;
  width: 100%;
  padding: 16px 8px;
  border-radius: 6px;
  background-color: var( --color-black );
  color: var( --color-cyan-1 );
  text-align: center;
  text-transform: uppercase;

  @media (--small-max) {
    font-size: rem( 24px );
  }

  @media (--medium) {
    padding: 16px 24px;
  }
}
