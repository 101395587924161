/* -----------------------------------------------------------------------------
 *
 * Organisms: Welcome
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/common.css';

/* -----------------------------------------------------------------------------
 * Section
 * ----------------------------------------------------------------------------- */

.section {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* -----------------------------------------------------------------------------
 * Nav
 * ----------------------------------------------------------------------------- */

.nav {
  margin-top: 32px;
}

/* -----------------------------------------------------------------------------
 * Nav Item
 * ----------------------------------------------------------------------------- */

.navItem {
  display: inline;
}

/* -----------------------------------------------------------------------------
 * Nav Button
 * ----------------------------------------------------------------------------- */

.navButton {
  display: block;
  width: 100%;
  margin-bottom: 16px;

  @media (--medium) {
    display: inline-block;
    width: auto;
    margin-right: 16px;
  }
}

.navButton + .navButton {
  margin-left: 0;
}
