/* -----------------------------------------------------------------------------
 *
 * Molecules: Play Demo
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/common.css';

/* -----------------------------------------------------------------------------
 * Nav
 * ----------------------------------------------------------------------------- */

.nav {
  margin-top: 32px;
  text-align: center;
}

/* -----------------------------------------------------------------------------
 * Nav Item
 * ----------------------------------------------------------------------------- */

.navItem {
  display: inline;
}

/* -----------------------------------------------------------------------------
 * Nav Button
 * ----------------------------------------------------------------------------- */

.navButton {
  display: block;
  width: 100%;
  margin-bottom: 16px;

  @media (--medium) {
    display: inline-block;
    width: auto;
    margin-right: 16px;
  }
}

.navButton + .navButton {
  margin-left: 0;
}

.navButton:last-of-type {
  margin-right: 0;
}
