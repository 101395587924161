/* -----------------------------------------------------------------------------
 *
 * Organisms: Welcome
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/common.css';

/* -----------------------------------------------------------------------------
 * Section
 * ----------------------------------------------------------------------------- */

.section {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* -----------------------------------------------------------------------------
 * Heading
 * ----------------------------------------------------------------------------- */

.heading {
  position: relative;
  margin-bottom: 24px;
  padding-bottom: 24px;
  font-weight: bold;
  line-height: 1.5;

  @media (--small) {
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: rem( 64px );
  }

  @media (--medium) {
    font-size: rem( 72px );
  }
}

.heading::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  margin-top: 24px;
  background-color: var( --color-mercury );

  @media (--small) {
    content: none;
  }
}

/* -----------------------------------------------------------------------------
 * Em Line
 * ----------------------------------------------------------------------------- */

.emLine {
  font-style: normal;
  text-transform: uppercase;
}

/* -----------------------------------------------------------------------------
 * Em
 * ----------------------------------------------------------------------------- */

.em {
  @media (--small-max) {
    font-size: rem( 16px );
  }
}

/* -----------------------------------------------------------------------------
 * Lead
 * ----------------------------------------------------------------------------- */

.lead {
  @media (--small-max) {
    font-size: rem( 18px );
  }

  @media (--small) {
    max-width: 80%;
    margin: 0 auto;
  }

  @media (--medium) {
    max-width: 680px;
  }
}
