/* -----------------------------------------------------------------------------
 *
 * Organisms: Bet Resolving Table
 *
 * ----------------------------------------------------------------------------- */

.table {
  width: 100%;
  max-width: 100%;
  font-size: rem( 16px );
}

/* -----------------------------------------------------------------------------
 * THead
 * ----------------------------------------------------------------------------- */

.table thead th {
  width: 20% !important;
  padding: 12px 16px 12px;
  background-color: var( --color-mine-shaft );
  color: var( --color-white );
  font-size: rem( 16px );
  line-height: lh( 16px, 24px );
}

/* -----------------------------------------------------------------------------
 * TBody
 * ----------------------------------------------------------------------------- */

.table tbody tr {
  padding: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-color: var( --color-white );
}

.table tbody tr:last-of-type {
  border-bottom: 0;
}

.table tbody th {
  padding: 12px 16px 11px;
  border-bottom: 1px solid var( --color-white );
  background-color: var( --color-white );
  font-size: rem( 16px );
  line-height: lh( 16px, 24px );
}

.table tbody td {
  padding: 12px 16px 11px;
  border-bottom: 1px solid var( --color-white );
}

.table tbody tr td:first-of-type {
  background-color: var( --color-mine-shaft );
  color: var( --color-white );
  font-weight: bolder;
}

.table tbody tr:last-of-type td {
  border: 0;
}

/* -----------------------------------------------------------------------------
 * Striped
 * ----------------------------------------------------------------------------- */

.striped tbody .tr:nth-child( odd ) th {
  background-color: var( --color-black );
}

.striped tbody tr:nth-child( even ) th {
  background-color: var( --color-neutral-light );
}

.striped tbody tr:nth-child( odd ) td {
  background-color: var( --color-neutral-dark );
}

.striped tbody tr:nth-child( even ) td {
  background-color: var( --color-neutral-light );
}
