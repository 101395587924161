/* -----------------------------------------------------------------------------
 *
 * Organisms: How To Play
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/common.css';

.content {
  column-gap: 32px;

  @media (--medium) {
    columns: 2;
  }

  @media (--large) {
    columns: 3;
  }
}

.italics {
  font-style: italic;
}
