/* -----------------------------------------------------------------------------
 *
 * Typography: Em
 *
 * ----------------------------------------------------------------------------- */

.em {
  display: inline-block;
  margin-top: 4px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var( --color-black );
  color: var( --color-cyan-1 );
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
}
