/* -----------------------------------------------------------------------------
 *
 * Typography: Lead
 *
 * ----------------------------------------------------------------------------- */

.lead {
  font-size: var( --lead-font-size );
  line-height: var( --lead-line-height );
}
